<template>
  <div class="content">
    <PageHeader title="Short Codes">
      <template v-slot:actions>
        <li>
          <router-link
            :to="'/admin/short-codes/create'"
            class="btn btn-success btn-floating"
          >
            <i
              class="
                fas fa-plus-circle
                text-white
                font-size-22
                v-align-text-bottom
              "
            ></i>
          </router-link>
        </li>
      </template>
    </PageHeader>
    <section class="page-content container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body block-el p-0">
              <div class="responsive-table">
                <div
                  v-if="!shortCodes.length && fetched"
                  class="text-center table-placeholder"
                >
                  <br />
                  <i class="fas fa-user font-size-80"></i>
                  <h5 class="card-title m-t-20">{{$t('customer-component.no-product')}}</h5>
                </div>
                <table v-if="fetched" class="table">
                  <thead class="bg-light" v-if="shortCodes.length">
                    <tr>
                      <th scope="col" class="text-left">{{$tc('generic-str.name', 1)}}</th>
                      <th scope="col">Short Code</th>
                      <th scope="col">{{$tc('generic-str.menu.providers', 2)}}</th>
                      <th scope="col" class="text-right">{{$t('generic-str.actions')}}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(shortCode, i) in shortCodes"
                      :key="shortCode.id"
                    >
                      <td data-label="Nome" class="text-left">
                        {{ shortCode.name }}
                      </td>
                      <td scope="row" data-label="Tipo">
                        <span class="badge badge-pill badge-success">{{
                          shortCode.number
                        }}</span>
                      </td>
                      <td data-label="Rota">
                        <span
                          v-for="provider in shortCode.providers"
                          :key="provider.id"
                          class="badge badge-pill badge-success"
                          >{{ provider.name }}</span
                        >
                      </td>
                      <td data-label class="text-right">
                        <div>
                          <div class="dropdown" style="margin-right: 15px">
                            <a
                              role="button"
                              class="dropdown-action"
                              :to="'#menu-dropdown-' + i"
                              type="button"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <i class="icon dripicons-gear text-success"></i>
                            </a>
                            <div
                              class="dropdown-menu dropdown-menu-right top-35"
                              :id="'menu-dropdown-' + i"
                              x-placement="bottom-end"
                              style
                            >
                              <router-link
                                :to="`/admin/short-codes/${shortCode.id}`"
                                class="dropdown-item"
                              >
                                {{$t('generic-str.edit')}}
                              </router-link>
                              <a
                                v-if="$store.state.auth.user.role == 'owner'"
                                class="dropdown-item"
                                @click="remove(shortCode.id)"
                                >{{$t('generic-str.remove')}}</a
                              >
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div
                  v-else
                  class="qt-block-ui relative"
                  style="padding: 120px"
                />
                <br />
                <pagination :lastPage="pages" @change="fetch" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import PageHeader from '@/components/PageHeader.vue';
import SmsService from '@/services/admin/sms.service';
import Pagination from '@/components/Pagination.vue';
import Swal from 'sweetalert2';

export default {
  name: 'ShortCodesIndex',
  components: {
    PageHeader,
    Pagination,
  },
  data() {
    return {
      fetched: false,
      shortCodes: [],
      form: {
        name: '',
        page: 1,
      },
      pages: 1,
      isSending: false,
    };
  },
  created() {
    this.fetch();
  },
  methods: {
    fetch(page = 1) {
      this.form.page = page;
      this.fetched = false;
      SmsService.getShortCodes(this.form).then(
        (response) => {
          this.fetched = true;
          this.shortCodes = response.data;
          this.pages = response.last_page;
        },
        (error) => {
          this.content = error;
        },
      );
    },
    remove(id) {
      Swal.fire({
        title: this.$t('generic-str.r-sure'),
        text: this.$t('customer-component.warning-product'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('warnings.btn-y-delete'),
      }).then((result) => {
        if (result.isConfirmed) {
          this.fetched = false;
          SmsService.deleteShortCode(id).then(
            () => {
              this.fetch(this.form.page);
              Swal.fire(`${this.$t('generic-str.deleted')}!`, this.$t('customer-component.product-removed'), 'success');
            },
            (error) => {
              this.content = error;
            },
          );
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
.btn-remove {
  color: #fff;
  margin-bottom: 5px;
}
.btn-new {
  margin-bottom: 5px;
}
.color-white {
  color: #fff;
}
.form-search {
  float: right;
  width: 100%;
}
.form-search button {
  border: solid 1px #ccc;
  border-left: 0px;
  text-align: center;
  border-radius: 0px 10px 10px 0px;
  height: 35px;
}
.form-search button i {
  font-size: 16px;
}
.w-100 {
  width: 100% !important;
}
</style>
